@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased; }
  body * {
    box-sizing: border-box; }

a:focus {
  outline: 0; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

ul {
  margin: 0;
  padding: 0; }

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2013 Schwartzco Inc.
        License: 1603-FJBWJG
*/
@font-face {
  font-family: 'Graphik Web';
  src: url("../fonts/Graphik-Semibold-Web.eot");
  src: url("../fonts/Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Graphik Web';
  src: url("../fonts/Graphik-Medium-Web.eot");
  src: url("../fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Graphik Web';
  src: url("../fonts/Graphik-Regular-Web.eot");
  src: url("../fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

.bg-raster {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 1144px;
  max-width: 100%;
  background-color: transparent;
  z-index: 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  pointer-events: none; }
  .bg-raster:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 33.33333334%;
    border-left: 1px solid #ddd; }
  .bg-raster:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 66.66666667%;
    border-left: 1px solid #ddd; }
  .bg-raster.dark {
    opacity: 0.1;
    border-left: 1px solid #000;
    border-right: 1px solid #000; }
    .bg-raster.dark:before {
      border-left: 1px solid #000; }
    .bg-raster.dark:after {
      border-left: 1px solid #000; }
  @media only screen and (max-width: 800px) {
    .bg-raster {
      width: 95%; }
      .bg-raster.dark {
        width: 95%; } }

.holder {
  display: block;
  margin: 0 auto;
  max-width: 1144px;
  padding: 0px 20px;
  position: relative; }
  @media only screen and (max-width: 800px) {
    .holder {
      padding: 0px 20px 30px; } }
  @media only screen and (max-width: 500px) {
    .holder {
      padding: 10px 0px 20px; } }
  .holder.wide {
    width: 1440px;
    max-width: 100%;
    padding: 0; }

.simple-holder {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1144px; }

.row {
  margin-top: 20px;
  width: 100%; }
  @media only screen and (max-width: 800px) {
    .row {
      margin-top: 20px; } }

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: auto; }

.align-center {
  text-align: center; }

.col-right {
  float: right !important;
  box-sizing: border-box; }

.col-center {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important; }

.col-1 {
  width: calc(100% *  1/12 - (40px - 40px * 1/12)); }
  .col-1:nth-child(n) {
    margin-right: 40px; }
  .col-1:nth-child(12n) {
    margin-right: 0; }
  .col-1:last-child {
    margin-right: 0; }
  .col-1.no--gutter {
    width: calc(100% *  1/12 - (0px - 0px * 1/12)); }
    .col-1.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-1.no--gutter:nth-child(12n) {
      margin-right: 0; }
    .col-1.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-1 {
      width: calc(100% *  1/12 - (20px - 20px * 1/12)); }
      .col-1:nth-child(n) {
        margin-right: 20px; }
      .col-1:nth-child(12n) {
        margin-right: 0; }
      .col-1:last-child {
        margin-right: 0; } }

.col-2 {
  width: calc(100% *  2/12 - (40px - 40px * 2/12)); }
  .col-2:nth-child(n) {
    margin-right: 40px; }
  .col-2:nth-child(6n) {
    margin-right: 0; }
  .col-2:last-child {
    margin-right: 0; }
  .col-2.no--gutter {
    width: calc(100% *  2/12 - (0px - 0px * 2/12)); }
    .col-2.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-2.no--gutter:nth-child(6n) {
      margin-right: 0; }
    .col-2.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-2 {
      width: calc(100% *  2/12 - (20px - 20px * 2/12)); }
      .col-2:nth-child(n) {
        margin-right: 20px; }
      .col-2:nth-child(6n) {
        margin-right: 0; }
      .col-2:last-child {
        margin-right: 0; } }

.col-3 {
  width: calc(100% *  3/12 - (40px - 40px * 3/12)); }
  .col-3:nth-child(n) {
    margin-right: 40px; }
  .col-3:nth-child(4n) {
    margin-right: 0; }
  .col-3:last-child {
    margin-right: 0; }
  .col-3.no--gutter {
    width: calc(100% *  3/12 - (0px - 0px * 3/12)); }
    .col-3.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-3.no--gutter:nth-child(4n) {
      margin-right: 0; }
    .col-3.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-3 {
      width: calc(100% *  3/12 - (20px - 20px * 3/12)); }
      .col-3:nth-child(n) {
        margin-right: 20px; }
      .col-3:nth-child(4n) {
        margin-right: 0; }
      .col-3:last-child {
        margin-right: 0; } }

.col-4 {
  width: calc(100% *  4/12 - (40px - 40px * 4/12)); }
  .col-4:nth-child(n) {
    margin-right: 40px; }
  .col-4:nth-child(3n) {
    margin-right: 0; }
  .col-4:last-child {
    margin-right: 0; }
  .col-4.no--gutter {
    width: calc(100% *  4/12 - (0px - 0px * 4/12)); }
    .col-4.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-4.no--gutter:nth-child(3n) {
      margin-right: 0; }
    .col-4.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-4 {
      width: calc(100% *  4/12 - (20px - 20px * 4/12)); }
      .col-4:nth-child(n) {
        margin-right: 20px; }
      .col-4:nth-child(3n) {
        margin-right: 0; }
      .col-4:last-child {
        margin-right: 0; } }

.col-5 {
  width: calc(100% *  5/12 - (40px - 40px * 5/12)); }
  .col-5:nth-child(n) {
    margin-right: 40px; }
  .col-5:nth-child(2.4n) {
    margin-right: 0; }
  .col-5:last-child {
    margin-right: 0; }
  .col-5.no--gutter {
    width: calc(100% *  5/12 - (0px - 0px * 5/12)); }
    .col-5.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-5.no--gutter:nth-child(2.4n) {
      margin-right: 0; }
    .col-5.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-5 {
      width: calc(100% *  5/12 - (20px - 20px * 5/12)); }
      .col-5:nth-child(n) {
        margin-right: 20px; }
      .col-5:nth-child(2.4n) {
        margin-right: 0; }
      .col-5:last-child {
        margin-right: 0; } }

.col-6 {
  width: calc(100% *  6/12 - (40px - 40px * 6/12)); }
  .col-6:nth-child(n) {
    margin-right: 40px; }
  .col-6:nth-child(2n) {
    margin-right: 0; }
  .col-6:last-child {
    margin-right: 0; }
  .col-6.no--gutter {
    width: calc(100% *  6/12 - (0px - 0px * 6/12)); }
    .col-6.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-6.no--gutter:nth-child(2n) {
      margin-right: 0; }
    .col-6.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-6 {
      width: calc(100% *  6/12 - (20px - 20px * 6/12)); }
      .col-6:nth-child(n) {
        margin-right: 20px; }
      .col-6:nth-child(2n) {
        margin-right: 0; }
      .col-6:last-child {
        margin-right: 0; } }

.col-7 {
  width: calc(100% *  7/12 - (40px - 40px * 7/12)); }
  .col-7:nth-child(n) {
    margin-right: 40px; }
  .col-7:nth-child(1.71429n) {
    margin-right: 0; }
  .col-7:last-child {
    margin-right: 0; }
  .col-7.no--gutter {
    width: calc(100% *  7/12 - (0px - 0px * 7/12)); }
    .col-7.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-7.no--gutter:nth-child(1.71429n) {
      margin-right: 0; }
    .col-7.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-7 {
      width: calc(100% *  7/12 - (20px - 20px * 7/12)); }
      .col-7:nth-child(n) {
        margin-right: 20px; }
      .col-7:nth-child(1.71429n) {
        margin-right: 0; }
      .col-7:last-child {
        margin-right: 0; } }

.col-8 {
  width: calc(100% *  8/12 - (40px - 40px * 8/12)); }
  .col-8:nth-child(n) {
    margin-right: 40px; }
  .col-8:nth-child(1.5n) {
    margin-right: 0; }
  .col-8:last-child {
    margin-right: 0; }
  .col-8.no--gutter {
    width: calc(100% *  8/12 - (0px - 0px * 8/12));
    margin-right: 0px; }
    .col-8.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-8.no--gutter:nth-child(1.5n) {
      margin-right: 0; }
    .col-8.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-8 {
      width: calc(100% *  8/12 - (20px - 20px * 8/12)); }
      .col-8:nth-child(n) {
        margin-right: 20px; }
      .col-8:nth-child(1.5n) {
        margin-right: 0; }
      .col-8:last-child {
        margin-right: 0; } }

.col-9 {
  width: calc(100% *  9/12 - (40px - 40px * 9/12)); }
  .col-9:nth-child(n) {
    margin-right: 40px; }
  .col-9:nth-child(1.33333n) {
    margin-right: 0; }
  .col-9:last-child {
    margin-right: 0; }
  .col-9.no--gutter {
    width: calc(100% *  9/12 - (0px - 0px * 9/12)); }
    .col-9.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-9.no--gutter:nth-child(1.33333n) {
      margin-right: 0; }
    .col-9.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-9 {
      width: calc(100% *  9/12 - (20px - 20px * 9/12)); }
      .col-9:nth-child(n) {
        margin-right: 20px; }
      .col-9:nth-child(1.33333n) {
        margin-right: 0; }
      .col-9:last-child {
        margin-right: 0; } }

.col-10 {
  width: calc(100% *  10/12 - (40px - 40px * 10/12)); }
  .col-10:nth-child(n) {
    margin-right: 40px; }
  .col-10:nth-child(1.2n) {
    margin-right: 0; }
  .col-10:last-child {
    margin-right: 0; }
  .col-10.no--gutter {
    width: calc(100% *  10/12 - (0px - 0px * 10/12)); }
    .col-10.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-10.no--gutter:nth-child(1.2n) {
      margin-right: 0; }
    .col-10.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-10 {
      width: calc(100% *  10/12 - (20px - 20px * 10/12)); }
      .col-10:nth-child(n) {
        margin-right: 20px; }
      .col-10:nth-child(1.2n) {
        margin-right: 0; }
      .col-10:last-child {
        margin-right: 0; } }

.col-11 {
  width: calc(100% *  11/12 - (40px - 40px * 11/12)); }
  .col-11:nth-child(n) {
    margin-right: 40px; }
  .col-11:nth-child(1.09091n) {
    margin-right: 0; }
  .col-11:last-child {
    margin-right: 0; }
  .col-11.no--gutter {
    width: calc(100% *  11/12 - (0px - 0px * 11/12)); }
    .col-11.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-11.no--gutter:nth-child(1.09091n) {
      margin-right: 0; }
    .col-11.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-11 {
      width: calc(100% *  11/12 - (20px - 20px * 11/12)); }
      .col-11:nth-child(n) {
        margin-right: 20px; }
      .col-11:nth-child(1.09091n) {
        margin-right: 0; }
      .col-11:last-child {
        margin-right: 0; } }

.col-12 {
  width: calc(100% *  12/12 - (40px - 40px * 12/12)); }
  .col-12:nth-child(n) {
    margin-right: 40px; }
  .col-12:nth-child(1n) {
    margin-right: 0; }
  .col-12:last-child {
    margin-right: 0; }
  .col-12.no--gutter {
    width: calc(100% *  12/12 - (0px - 0px * 12/12)); }
    .col-12.no--gutter:nth-child(n) {
      margin-right: 0px; }
    .col-12.no--gutter:nth-child(1n) {
      margin-right: 0; }
    .col-12.no--gutter:last-child {
      margin-right: 0; }
  @media only screen and (max-width: 800px) {
    .col-12 {
      width: calc(100% *  12/12 - (20px - 20px * 12/12)); }
      .col-12:nth-child(n) {
        margin-right: 20px; }
      .col-12:nth-child(1n) {
        margin-right: 0; }
      .col-12:last-child {
        margin-right: 0; } }

@media only screen and (max-width: 1050px) {
  .l-col-1 {
    width: calc(100% *  1/12 - (40px - 40px * 1/12)); }
    .l-col-1:nth-child(n) {
      margin-right: 40px; }
    .l-col-1:nth-child(12n) {
      margin-right: 0; }
    .l-col-1:last-child {
      margin-right: 0; }
    .l-col-1.no--gutter {
      width: calc(100% *  1/12 - (0px - 0px * 1/12)); }
      .l-col-1.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-1.no--gutter:nth-child(12n) {
        margin-right: 0; }
      .l-col-1.no--gutter:last-child {
        margin-right: 0; }
  .l-col-2 {
    width: calc(100% *  2/12 - (40px - 40px * 2/12)); }
    .l-col-2:nth-child(n) {
      margin-right: 40px; }
    .l-col-2:nth-child(6n) {
      margin-right: 0; }
    .l-col-2:last-child {
      margin-right: 0; }
    .l-col-2.no--gutter {
      width: calc(100% *  2/12 - (0px - 0px * 2/12)); }
      .l-col-2.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-2.no--gutter:nth-child(6n) {
        margin-right: 0; }
      .l-col-2.no--gutter:last-child {
        margin-right: 0; }
  .l-col-3 {
    width: calc(100% *  3/12 - (40px - 40px * 3/12)); }
    .l-col-3:nth-child(n) {
      margin-right: 40px; }
    .l-col-3:nth-child(4n) {
      margin-right: 0; }
    .l-col-3:last-child {
      margin-right: 0; }
    .l-col-3.no--gutter {
      width: calc(100% *  3/12 - (0px - 0px * 3/12)); }
      .l-col-3.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-3.no--gutter:nth-child(4n) {
        margin-right: 0; }
      .l-col-3.no--gutter:last-child {
        margin-right: 0; }
  .l-col-4 {
    width: calc(100% *  4/12 - (40px - 40px * 4/12)); }
    .l-col-4:nth-child(n) {
      margin-right: 40px; }
    .l-col-4:nth-child(3n) {
      margin-right: 0; }
    .l-col-4:last-child {
      margin-right: 0; }
    .l-col-4.no--gutter {
      width: calc(100% *  4/12 - (0px - 0px * 4/12)); }
      .l-col-4.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-4.no--gutter:nth-child(3n) {
        margin-right: 0; }
      .l-col-4.no--gutter:last-child {
        margin-right: 0; }
  .l-col-5 {
    width: calc(100% *  5/12 - (40px - 40px * 5/12)); }
    .l-col-5:nth-child(n) {
      margin-right: 40px; }
    .l-col-5:nth-child(2.4n) {
      margin-right: 0; }
    .l-col-5:last-child {
      margin-right: 0; }
    .l-col-5.no--gutter {
      width: calc(100% *  5/12 - (0px - 0px * 5/12)); }
      .l-col-5.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-5.no--gutter:nth-child(2.4n) {
        margin-right: 0; }
      .l-col-5.no--gutter:last-child {
        margin-right: 0; }
  .l-col-6 {
    width: calc(100% *  6/12 - (40px - 40px * 6/12)); }
    .l-col-6:nth-child(n) {
      margin-right: 40px; }
    .l-col-6:nth-child(2n) {
      margin-right: 0; }
    .l-col-6:last-child {
      margin-right: 0; }
    .l-col-6.no--gutter {
      width: calc(100% *  6/12 - (0px - 0px * 6/12)); }
      .l-col-6.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-6.no--gutter:nth-child(2n) {
        margin-right: 0; }
      .l-col-6.no--gutter:last-child {
        margin-right: 0; }
  .l-col-7 {
    width: calc(100% *  7/12 - (40px - 40px * 7/12)); }
    .l-col-7:nth-child(n) {
      margin-right: 40px; }
    .l-col-7:nth-child(1.71429n) {
      margin-right: 0; }
    .l-col-7:last-child {
      margin-right: 0; }
    .l-col-7.no--gutter {
      width: calc(100% *  7/12 - (0px - 0px * 7/12)); }
      .l-col-7.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-7.no--gutter:nth-child(1.71429n) {
        margin-right: 0; }
      .l-col-7.no--gutter:last-child {
        margin-right: 0; }
  .l-col-8 {
    width: calc(100% *  8/12 - (40px - 40px * 8/12)); }
    .l-col-8:nth-child(n) {
      margin-right: 40px; }
    .l-col-8:nth-child(1.5n) {
      margin-right: 0; }
    .l-col-8:last-child {
      margin-right: 0; }
    .l-col-8.no--gutter {
      width: calc(100% *  8/12 - (0px - 0px * 8/12)); }
      .l-col-8.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-8.no--gutter:nth-child(1.5n) {
        margin-right: 0; }
      .l-col-8.no--gutter:last-child {
        margin-right: 0; }
  .l-col-9 {
    width: calc(100% *  9/12 - (40px - 40px * 9/12)); }
    .l-col-9:nth-child(n) {
      margin-right: 40px; }
    .l-col-9:nth-child(1.33333n) {
      margin-right: 0; }
    .l-col-9:last-child {
      margin-right: 0; }
    .l-col-9.no--gutter {
      width: calc(100% *  9/12 - (0px - 0px * 9/12)); }
      .l-col-9.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-9.no--gutter:nth-child(1.33333n) {
        margin-right: 0; }
      .l-col-9.no--gutter:last-child {
        margin-right: 0; }
  .l-col-10 {
    width: calc(100% *  10/12 - (40px - 40px * 10/12)); }
    .l-col-10:nth-child(n) {
      margin-right: 40px; }
    .l-col-10:nth-child(1.2n) {
      margin-right: 0; }
    .l-col-10:last-child {
      margin-right: 0; }
    .l-col-10.no--gutter {
      width: calc(100% *  10/12 - (0px - 0px * 10/12)); }
      .l-col-10.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-10.no--gutter:nth-child(1.2n) {
        margin-right: 0; }
      .l-col-10.no--gutter:last-child {
        margin-right: 0; }
  .l-col-11 {
    width: calc(100% *  11/12 - (40px - 40px * 11/12)); }
    .l-col-11:nth-child(n) {
      margin-right: 40px; }
    .l-col-11:nth-child(1.09091n) {
      margin-right: 0; }
    .l-col-11:last-child {
      margin-right: 0; }
    .l-col-11.no--gutter {
      width: calc(100% *  11/12 - (0px - 0px * 11/12)); }
      .l-col-11.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-11.no--gutter:nth-child(1.09091n) {
        margin-right: 0; }
      .l-col-11.no--gutter:last-child {
        margin-right: 0; }
  .l-col-12 {
    width: calc(100% *  12/12 - (40px - 40px * 12/12)); }
    .l-col-12:nth-child(n) {
      margin-right: 40px; }
    .l-col-12:nth-child(1n) {
      margin-right: 0; }
    .l-col-12:last-child {
      margin-right: 0; }
    .l-col-12.no--gutter {
      width: calc(100% *  12/12 - (0px - 0px * 12/12)); }
      .l-col-12.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .l-col-12.no--gutter:nth-child(1n) {
        margin-right: 0; }
      .l-col-12.no--gutter:last-child {
        margin-right: 0; } }

@media only screen and (max-width: 800px) {
  .m-col-1 {
    width: calc(100% *  1/12 - (20px - 20px * 1/12)); }
    .m-col-1:nth-child(n) {
      margin-right: 20px; }
    .m-col-1:nth-child(12n) {
      margin-right: 0; }
    .m-col-1:last-child {
      margin-right: 0; }
    .m-col-1.no--gutter {
      width: calc(100% *  1/12 - (0px - 0px * 1/12)); }
      .m-col-1.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-1.no--gutter:nth-child(12n) {
        margin-right: 0; }
      .m-col-1.no--gutter:last-child {
        margin-right: 0; }
  .m-col-2 {
    width: calc(100% *  2/12 - (20px - 20px * 2/12)); }
    .m-col-2:nth-child(n) {
      margin-right: 20px; }
    .m-col-2:nth-child(6n) {
      margin-right: 0; }
    .m-col-2:last-child {
      margin-right: 0; }
    .m-col-2.no--gutter {
      width: calc(100% *  2/12 - (0px - 0px * 2/12)); }
      .m-col-2.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-2.no--gutter:nth-child(6n) {
        margin-right: 0; }
      .m-col-2.no--gutter:last-child {
        margin-right: 0; }
  .m-col-3 {
    width: calc(100% *  3/12 - (20px - 20px * 3/12)); }
    .m-col-3:nth-child(n) {
      margin-right: 20px; }
    .m-col-3:nth-child(4n) {
      margin-right: 0; }
    .m-col-3:last-child {
      margin-right: 0; }
    .m-col-3.no--gutter {
      width: calc(100% *  3/12 - (0px - 0px * 3/12)); }
      .m-col-3.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-3.no--gutter:nth-child(4n) {
        margin-right: 0; }
      .m-col-3.no--gutter:last-child {
        margin-right: 0; }
  .m-col-4 {
    width: calc(100% *  4/12 - (20px - 20px * 4/12)); }
    .m-col-4:nth-child(n) {
      margin-right: 20px; }
    .m-col-4:nth-child(3n) {
      margin-right: 0; }
    .m-col-4:last-child {
      margin-right: 0; }
    .m-col-4.no--gutter {
      width: calc(100% *  4/12 - (0px - 0px * 4/12)); }
      .m-col-4.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-4.no--gutter:nth-child(3n) {
        margin-right: 0; }
      .m-col-4.no--gutter:last-child {
        margin-right: 0; }
  .m-col-5 {
    width: calc(100% *  5/12 - (20px - 20px * 5/12)); }
    .m-col-5:nth-child(n) {
      margin-right: 20px; }
    .m-col-5:nth-child(2.4n) {
      margin-right: 0; }
    .m-col-5:last-child {
      margin-right: 0; }
    .m-col-5.no--gutter {
      width: calc(100% *  5/12 - (0px - 0px * 5/12)); }
      .m-col-5.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-5.no--gutter:nth-child(2.4n) {
        margin-right: 0; }
      .m-col-5.no--gutter:last-child {
        margin-right: 0; }
  .m-col-6 {
    width: calc(100% *  6/12 - (20px - 20px * 6/12)); }
    .m-col-6:nth-child(n) {
      margin-right: 20px; }
    .m-col-6:nth-child(2n) {
      margin-right: 0; }
    .m-col-6:last-child {
      margin-right: 0; }
    .m-col-6.no--gutter {
      width: calc(100% *  6/12 - (0px - 0px * 6/12)); }
      .m-col-6.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-6.no--gutter:nth-child(2n) {
        margin-right: 0; }
      .m-col-6.no--gutter:last-child {
        margin-right: 0; }
  .m-col-7 {
    width: calc(100% *  7/12 - (20px - 20px * 7/12)); }
    .m-col-7:nth-child(n) {
      margin-right: 20px; }
    .m-col-7:nth-child(1.71429n) {
      margin-right: 0; }
    .m-col-7:last-child {
      margin-right: 0; }
    .m-col-7.no--gutter {
      width: calc(100% *  7/12 - (0px - 0px * 7/12)); }
      .m-col-7.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-7.no--gutter:nth-child(1.71429n) {
        margin-right: 0; }
      .m-col-7.no--gutter:last-child {
        margin-right: 0; }
  .m-col-8 {
    width: calc(100% *  8/12 - (20px - 20px * 8/12)); }
    .m-col-8:nth-child(n) {
      margin-right: 20px; }
    .m-col-8:nth-child(1.5n) {
      margin-right: 0; }
    .m-col-8:last-child {
      margin-right: 0; }
    .m-col-8.no--gutter {
      width: calc(100% *  8/12 - (0px - 0px * 8/12)); }
      .m-col-8.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-8.no--gutter:nth-child(1.5n) {
        margin-right: 0; }
      .m-col-8.no--gutter:last-child {
        margin-right: 0; }
  .m-col-9 {
    width: calc(100% *  9/12 - (20px - 20px * 9/12)); }
    .m-col-9:nth-child(n) {
      margin-right: 20px; }
    .m-col-9:nth-child(1.33333n) {
      margin-right: 0; }
    .m-col-9:last-child {
      margin-right: 0; }
    .m-col-9.no--gutter {
      width: calc(100% *  9/12 - (0px - 0px * 9/12)); }
      .m-col-9.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-9.no--gutter:nth-child(1.33333n) {
        margin-right: 0; }
      .m-col-9.no--gutter:last-child {
        margin-right: 0; }
  .m-col-10 {
    width: calc(100% *  10/12 - (20px - 20px * 10/12)); }
    .m-col-10:nth-child(n) {
      margin-right: 20px; }
    .m-col-10:nth-child(1.2n) {
      margin-right: 0; }
    .m-col-10:last-child {
      margin-right: 0; }
    .m-col-10.no--gutter {
      width: calc(100% *  10/12 - (0px - 0px * 10/12)); }
      .m-col-10.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-10.no--gutter:nth-child(1.2n) {
        margin-right: 0; }
      .m-col-10.no--gutter:last-child {
        margin-right: 0; }
  .m-col-11 {
    width: calc(100% *  11/12 - (20px - 20px * 11/12)); }
    .m-col-11:nth-child(n) {
      margin-right: 20px; }
    .m-col-11:nth-child(1.09091n) {
      margin-right: 0; }
    .m-col-11:last-child {
      margin-right: 0; }
    .m-col-11.no--gutter {
      width: calc(100% *  11/12 - (0px - 0px * 11/12)); }
      .m-col-11.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .m-col-11.no--gutter:nth-child(1.09091n) {
        margin-right: 0; }
      .m-col-11.no--gutter:last-child {
        margin-right: 0; }
  .m-col-12 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important; } }

[class*="col-"] {
  float: left;
  position: relative;
  vertical-align: top; }
  @media only screen and (max-width: 500px) {
    [class*="col-"] {
      width: 100%;
      margin: 12.5px 0; } }

@media only screen and (max-width: 500px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .s-col-1 {
    width: calc(100% *  1/12 - (40px - 40px * 1/12)); }
    .s-col-1:nth-child(n) {
      margin-right: 40px; }
    .s-col-1:nth-child(12n) {
      margin-right: 0; }
    .s-col-1:last-child {
      margin-right: 0; }
    .s-col-1.no--gutter {
      width: calc(100% *  1/12 - (0px - 0px * 1/12)); }
      .s-col-1.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-1.no--gutter:nth-child(12n) {
        margin-right: 0; }
      .s-col-1.no--gutter:last-child {
        margin-right: 0; }
  .s-col-2 {
    width: calc(100% *  2/12 - (40px - 40px * 2/12)); }
    .s-col-2:nth-child(n) {
      margin-right: 40px; }
    .s-col-2:nth-child(6n) {
      margin-right: 0; }
    .s-col-2:last-child {
      margin-right: 0; }
    .s-col-2.no--gutter {
      width: calc(100% *  2/12 - (0px - 0px * 2/12)); }
      .s-col-2.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-2.no--gutter:nth-child(6n) {
        margin-right: 0; }
      .s-col-2.no--gutter:last-child {
        margin-right: 0; }
  .s-col-3 {
    width: calc(100% *  3/12 - (40px - 40px * 3/12)); }
    .s-col-3:nth-child(n) {
      margin-right: 40px; }
    .s-col-3:nth-child(4n) {
      margin-right: 0; }
    .s-col-3:last-child {
      margin-right: 0; }
    .s-col-3.no--gutter {
      width: calc(100% *  3/12 - (0px - 0px * 3/12)); }
      .s-col-3.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-3.no--gutter:nth-child(4n) {
        margin-right: 0; }
      .s-col-3.no--gutter:last-child {
        margin-right: 0; }
  .s-col-4 {
    width: calc(100% *  4/12 - (40px - 40px * 4/12));
    margin-right: 40px !important; }
    .s-col-4:nth-child(n) {
      margin-right: 40px; }
    .s-col-4:nth-child(3n) {
      margin-right: 0; }
    .s-col-4:last-child {
      margin-right: 0; }
    .s-col-4:nth-child(3n) {
      margin-right: 0px !important; }
    .s-col-4.no--gutter {
      width: calc(100% *  4/12 - (0px - 0px * 4/12)); }
      .s-col-4.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-4.no--gutter:nth-child(3n) {
        margin-right: 0; }
      .s-col-4.no--gutter:last-child {
        margin-right: 0; }
  .s-col-5 {
    width: calc(100% *  5/12 - (40px - 40px * 5/12)); }
    .s-col-5:nth-child(n) {
      margin-right: 40px; }
    .s-col-5:nth-child(2.4n) {
      margin-right: 0; }
    .s-col-5:last-child {
      margin-right: 0; }
    .s-col-5.no--gutter {
      width: calc(100% *  5/12 - (0px - 0px * 5/12)); }
      .s-col-5.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-5.no--gutter:nth-child(2.4n) {
        margin-right: 0; }
      .s-col-5.no--gutter:last-child {
        margin-right: 0; }
  .s-col-6 {
    width: calc(100% *  6/12 - (40px - 40px * 6/12)); }
    .s-col-6:nth-child(n) {
      margin-right: 40px; }
    .s-col-6:nth-child(2n) {
      margin-right: 0; }
    .s-col-6:last-child {
      margin-right: 0; }
    .s-col-6.no--gutter {
      width: calc(100% *  6/12 - (0px - 0px * 6/12)); }
      .s-col-6.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-6.no--gutter:nth-child(2n) {
        margin-right: 0; }
      .s-col-6.no--gutter:last-child {
        margin-right: 0; }
  .s-col-7 {
    width: calc(100% *  7/12 - (40px - 40px * 7/12)); }
    .s-col-7:nth-child(n) {
      margin-right: 40px; }
    .s-col-7:nth-child(1.71429n) {
      margin-right: 0; }
    .s-col-7:last-child {
      margin-right: 0; }
    .s-col-7.no--gutter {
      width: calc(100% *  7/12 - (0px - 0px * 7/12)); }
      .s-col-7.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-7.no--gutter:nth-child(1.71429n) {
        margin-right: 0; }
      .s-col-7.no--gutter:last-child {
        margin-right: 0; }
  .s-col-8 {
    width: calc(100% *  8/12 - (40px - 40px * 8/12)); }
    .s-col-8:nth-child(n) {
      margin-right: 40px; }
    .s-col-8:nth-child(1.5n) {
      margin-right: 0; }
    .s-col-8:last-child {
      margin-right: 0; }
    .s-col-8.no--gutter {
      width: calc(100% *  8/12 - (0px - 0px * 8/12)); }
      .s-col-8.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-8.no--gutter:nth-child(1.5n) {
        margin-right: 0; }
      .s-col-8.no--gutter:last-child {
        margin-right: 0; }
  .s-col-9 {
    width: calc(100% *  9/12 - (40px - 40px * 9/12)); }
    .s-col-9:nth-child(n) {
      margin-right: 40px; }
    .s-col-9:nth-child(1.33333n) {
      margin-right: 0; }
    .s-col-9:last-child {
      margin-right: 0; }
    .s-col-9.no--gutter {
      width: calc(100% *  9/12 - (0px - 0px * 9/12)); }
      .s-col-9.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-9.no--gutter:nth-child(1.33333n) {
        margin-right: 0; }
      .s-col-9.no--gutter:last-child {
        margin-right: 0; }
  .s-col-10 {
    width: calc(100% *  10/12 - (40px - 40px * 10/12)); }
    .s-col-10:nth-child(n) {
      margin-right: 40px; }
    .s-col-10:nth-child(1.2n) {
      margin-right: 0; }
    .s-col-10:last-child {
      margin-right: 0; }
    .s-col-10.no--gutter {
      width: calc(100% *  10/12 - (0px - 0px * 10/12)); }
      .s-col-10.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-10.no--gutter:nth-child(1.2n) {
        margin-right: 0; }
      .s-col-10.no--gutter:last-child {
        margin-right: 0; }
  .s-col-11 {
    width: calc(100% *  11/12 - (40px - 40px * 11/12)); }
    .s-col-11:nth-child(n) {
      margin-right: 40px; }
    .s-col-11:nth-child(1.09091n) {
      margin-right: 0; }
    .s-col-11:last-child {
      margin-right: 0; }
    .s-col-11.no--gutter {
      width: calc(100% *  11/12 - (0px - 0px * 11/12)); }
      .s-col-11.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-11.no--gutter:nth-child(1.09091n) {
        margin-right: 0; }
      .s-col-11.no--gutter:last-child {
        margin-right: 0; }
  .s-col-12 {
    width: calc(100% *  12/12 - (40px - 40px * 12/12)); }
    .s-col-12:nth-child(n) {
      margin-right: 40px; }
    .s-col-12:nth-child(1n) {
      margin-right: 0; }
    .s-col-12:last-child {
      margin-right: 0; }
    .s-col-12.no--gutter {
      width: calc(100% *  12/12 - (0px - 0px * 12/12)); }
      .s-col-12.no--gutter:nth-child(n) {
        margin-right: 0px; }
      .s-col-12.no--gutter:nth-child(1n) {
        margin-right: 0; }
      .s-col-12.no--gutter:last-child {
        margin-right: 0; } }

body {
  font-family: 'Graphik Web'; }

.spawn {
  position: absolute;
  z-index: -1; }
  @media only screen and (max-width: 1050px) {
    .spawn {
      display: none; } }
  .spawn.spawn-top-left {
    width: 306px;
    height: auto;
    top: 0;
    left: 4%; }
  .spawn.spawn-top-left2 {
    width: 176px;
    left: 2%;
    top: 13%; }
  .spawn.spawn-top-right {
    right: 0;
    top: 0;
    width: 370px; }
  .spawn.spawn-middle-right {
    right: 0;
    top: 25%;
    width: 318px; }
  .spawn.spawn-bottom-left1 {
    left: 0;
    bottom: 27%;
    width: 438px; }
  .spawn.spawn-bottom-left2 {
    left: 10%;
    bottom: 5%;
    width: 252px; }
  .spawn.spawn-bottom-right {
    right: 10%;
    bottom: 0%;
    width: 231px; }

.greeting {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  line-height: 43px;
  text-align: center;
  max-width: 79%;
  margin: 0 auto; }
  .greeting span {
    color: #0075FA; }
  @media only screen and (max-width: 800px) {
    .greeting {
      font-size: 18px;
      line-height: 32px;
      max-width: 90%; } }

.greeting-copy {
  text-align: center;
  margin-top: 40px; }

.main {
  position: relative;
  width: 1440px;
  margin: 0 auto;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: visible; }
  @media only screen and (max-width: 800px) {
    .main {
      padding: 0 8px; } }
  @media only screen and (max-width: 500px) {
    .main {
      padding: 0 24px; } }

.nav {
  padding: 36px 0;
  display: block;
  text-align: center; }
  .nav img {
    width: 140px;
    margin: 0 20px; }
    @media only screen and (max-width: 800px) {
      .nav img {
        width: 100px; } }
    .nav img.signal {
      transform: translateY(-10px); }

body.home {
  height: 100vh; }

body.export-view .logo-export {
  width: 140px;
  display: block;
  margin: 20px auto 20px auto; }

body.export-view h1 {
  text-align: center;
  margin-bottom: 20px; }

body.export-view p {
  text-align: center;
  margin-bottom: 20px; }

body.export-view .export-result {
  width: 100%; }
  body.export-view .export-result th {
    background-color: #1482fa;
    color: #fff;
    text-align: left; }
  body.export-view .export-result tr:nth-child(odd) {
    background: #f2f2f2; }

.index-logo-holder {
  transform: translateY(37%);
  width: 200px;
  height: 100vh;
  margin: 0 auto; }
  .index-logo-holder img {
    max-width: 100%; }

.block {
  display: block;
  margin-bottom: 140px; }
  @media only screen and (max-width: 800px) {
    .block {
      margin-bottom: 60px; } }
  @media only screen and (max-width: 500px) {
    .block {
      margin-bottom: 30px; } }

.intro {
  margin-top: 40px;
  margin-bottom: 80px;
  position: relative; }
  .intro img {
    width: 100%; }
  @media only screen and (max-width: 800px) {
    .intro {
      margin-top: 30px;
      margin-bottom: 90px; } }
  @media only screen and (max-width: 500px) {
    .intro {
      margin-top: 30px;
      margin-bottom: 30px; } }

.text-block {
  padding: 40px;
  background-color: #1482fa;
  color: #fff; }
  .text-block p {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 20px; }
  @media only screen and (max-width: 500px) {
    .text-block {
      padding: 20px; } }

.intro-title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 50px;
  color: #000000;
  letter-spacing: 6.68px;
  line-height: 67px;
  text-align: center;
  max-width: 676px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  margin-top: -140px;
  display: inline-block; }
  @media only screen and (max-width: 800px) {
    .intro-title {
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 2px; } }

.intro-text {
  width: 100%;
  text-align: center; }

.intro-date {
  font-weight: 600;
  font-size: 385px;
  color: #000000;
  letter-spacing: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.06;
  transform: translate(-50%, -50%); }
  @media only screen and (max-width: 800px) {
    .intro-date {
      font-size: 145px; } }

.intro-copy {
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 38px; }
  .intro-copy b {
    font-weight: 500; }
  @media only screen and (max-width: 800px) {
    .intro-copy {
      font-size: 18px;
      line-height: 32px; } }

.osa {
  margin-bottom: 140px; }

.osa-title {
  /* Varmt välkommen till: */
  font-weight: 500;
  font-size: 24px;
  color: #047AFA;
  line-height: 43px;
  margin-top: 40px; }
  .osa-title span {
    color: #000; }
  @media only screen and (max-width: 800px) {
    .osa-title {
      font-size: 28px;
      line-height: 38px; } }

.copy {
  /* Någon typ av instruk: */
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 29px;
  position: relative; }

.result {
  opacity: 0;
  transition: .2s; }
  .result.active {
    opacity: 1; }
  .result p {
    color: #000;
    letter-spacing: 0;
    line-height: 29px;
    position: relative;
    font-size: 15px;
    margin-top: 24px; }

h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  font-weight: 500;
  letter-spacing: 2.5px;
  text-transform: uppercase; }

.title {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  letter-spacing: 2.5px;
  line-height: 58px;
  text-transform: uppercase;
  margin-bottom: 0px; }
  .title.form-title {
    margin-bottom: 24px; }
  .title.black {
    color: black; }
  .title.small {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px; }
  @media only screen and (max-width: 800px) {
    .title {
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 2px; } }

.text-block a {
  color: black;
  text-decoration: underline; }

input[type=text] {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 40px;
  display: block;
  width: 100%;
  font-size: 22px;
  padding-bottom: 12px;
  color: #2A2929;
  outline: none;
  transition: .2s; }
  input[type=text]:focus {
    outline: none; }
  input[type=text] ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000;
    opacity: 0.5;
    font-style: italic; }
  input[type=text] ::-moz-placeholder {
    /* Firefox 19+ */
    color: #000;
    opacity: 0.5;
    font-style: italic; }
  input[type=text] :-ms-input-placeholder {
    /* IE 10+ */
    color: #000;
    opacity: 0.5;
    font-style: italic; }
  input[type=text] :-moz-placeholder {
    /* Firefox 18- */
    color: #000;
    opacity: 0.5;
    font-style: italic; }
  @media only screen and (max-width: 800px) {
    input[type=text] {
      font-size: 18px; } }
  input[type=text].error {
    border-left: 5px solid #FB0000; }

.livin {
  display: block;
  margin-bottom: 140px; }

.button {
  font-size: 22px;
  color: #000000;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer; }

.preline {
  padding-top: 24px;
  position: relative; }
  .preline:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #000000;
    position: absolute;
    top: 0; }
  .preline.blue:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #047AFA;
    position: absolute;
    top: 0; }

.postline {
  padding-bottom: 0px;
  margin-bottom: 24px;
  position: relative; }
  .postline:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #000000;
    position: absolute;
    bottom: 0; }
  .postline.blue:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #047AFA;
    position: absolute;
    bottom: 0; }

.timetable {
  margin-top: 24px; }
  .timetable .item {
    margin-bottom: 24px; }
  .timetable p {
    font-size: 16px;
    line-height: 23px; }
    .timetable p.bold {
      font-weight: 500; }
    .timetable p.blue {
      color: #047AFA; }

.footer {
  width: 100%;
  background: #fff;
  font-size: 14px;
  color: #0F0F0F;
  font-weight: 600;
  overflow: hidden;
  z-index: 9;
  position: relative;
  text-align: center; }

.footer-wrap {
  width: 100%;
  max-width: 908px;
  padding: 15px 20px;
  margin: 0 auto; }

.footer img {
  width: 120px;
  margin: 0 10px; }

.footer-slogan {
  display: inline-block;
  margin-top: 10px;
  padding-bottom: 20px;
  line-height: 1.35;
  float: right; }

.speakers {
  display: block; }

.speaker {
  position: relative;
  padding-top: 12px;
  margin-bottom: 36px; }
  .speaker img {
    margin: 12px 0;
    width: 100%; }

.signup-table {
  min-height: 80vh;
  height: auto;
  padding-bottom: 80px; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 3rem;
  text-align: left; }

tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03); }

tr:hover {
  background: rgba(0, 0, 0, 0.05); }

th {
  color: #fff;
  padding: 20px; }

td {
  padding: 20px;
  width: calc(100% / 3); }

input[type="submit"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; }
