@import 'normalize';
@import 'breaker';
@import 'mixins';

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

//Graphik
/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2013 Schwartzco Inc.
        License: 1603-FJBWJG
*/
@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Semibold-Web.eot');
	src: url('../fonts/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Semibold-Web.woff') format('woff');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Medium-Web.eot');
	src: url('../fonts/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Medium-Web.woff') format('woff');
	font-weight:  500;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Regular-Web.eot');
	src: url('../fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Regular-Web.woff') format('woff');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}


// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
@import 'grid';
body {
    font-family:'Graphik Web';

}

.spawn {
    position: absolute;
    z-index:-1;
    @include below($l) {
        display:none;
    }
    &.spawn-top-left {
        width:306px;
        height:auto;
        top:0;
        left:4%;
    }
    &.spawn-top-left2 {
        width:176px;
        left:2%;
        top:13%;
    }
    &.spawn-top-right {
        right:0;
        top:0;
        width:370px;
    }
    &.spawn-middle-right {
        right:0;
        top:25%;
        width:318px;
    }
    &.spawn-bottom-left1 {
        left:0;
        bottom:27%;
        width:438px;
    }
    &.spawn-bottom-left2 {
        left:10%;
        bottom:5%;
        width:252px;
    }
    &.spawn-bottom-right {
        right:10%;
        bottom:0%;
        width:231px;
    }
}
.greeting {
    font-weight:500;
    font-size: 30px;
    color: #000000;
    line-height: 43px;
    text-align:center;
    max-width:79%;
    margin:0 auto;
    span {
        color:#0075FA;
    }
    @include below($m) {
        font-size:18px;
        line-height:32px;
        max-width:90%;
    }
}
.greeting-copy {
    text-align:center;
    margin-top:40px;
}
.main {
    position:relative;
    width:$siteMaxWidth;
    margin:0 auto;
    max-width:100%;
    overflow-y:hidden;
    overflow-x:visible;
    @include below($m) {
        padding:0 8px;
    }
    @include below($s) {
        padding:0 24px;
    }
}
.nav {
    padding:36px 0;
    display:block;
    text-align:center;
    img {
        width:140px;
        margin:0 20px;
        @include below($m) {
            width:100px;
        }
        &.signal {
            transform:translateY(-10px);
        }
    }
}
body.home {
    height: 100vh;
}
body.export-view {
    .logo-export {
        width: 140px;
        display: block;
        margin: 20px auto 20px auto;
    }
    h1 {
        text-align: center;
        margin-bottom: 20px;
    }
    p {
        text-align: center;
        margin-bottom: 20px;
    }
    .export-result {
        width: 100%;
        th {
            background-color: #1482fa;
            color: #fff;
            text-align: left;
        }
        tr:nth-child(odd){
            background: #f2f2f2;
        }
        
    }
}
.index-logo-holder {
    transform:translateY(37%);
    width: 200px;
    height: 100vh;
    margin: 0 auto;
    img {
        max-width: 100%;
    }
}
.block {
    display:block;
    margin-bottom:140px;
    @include below($m) {
        margin-bottom:60px;
    }
    @include below($s) {
        margin-bottom:30px;
    }
}
.intro {
    margin-top:40px;
    margin-bottom:80px;
    position: relative;
    img {
        width:100%;
    }
    @include below($m) {
        margin-top:30px;
        margin-bottom:90px;
    }
    @include below($s) {
        margin-top:30px;
        margin-bottom:30px;
    }
}
.text-block {
    padding:40px;
    background-color: #1482fa;
    color: #fff;
    p {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 38px;
        margin-bottom: 20px;
    }
    @include below($s) {
        padding: 20px;
    }
}
.intro-title {
    font-weight:600;
    text-transform: uppercase;
    font-size: 50px;
    color: #000000;
    letter-spacing: 6.68px;
    line-height: 67px;
    text-align:center;
    max-width:676px;
    margin:0 auto;
    padding:40px;
    background-color:white;
    margin-top:-140px;
    display:inline-block;
    @include below($m) {
        font-size:28px;
        line-height:38px;
        letter-spacing:2px;
    }
}
.intro-text {
    width:100%;
    text-align:center;
}
.intro-date {
    font-weight:600;
    font-size: 385px;
    color: #000000;
    letter-spacing: 0;
    position: absolute;
    top:50%;
    left:50%;
    opacity:0.06;
    transform:translate(-50%, -50%);
    @include below($m) {
        font-size:145px;
    }
}
.intro-copy {

    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 38px;
    b {
        font-weight:500;
    }
    @include below($m) {
        font-size:18px;
        line-height:32px;
    }
}
.osa {
    margin-bottom:140px;
}
.osa-title {
    /* Varmt välkommen till: */
    font-weight:500;
    font-size: 24px;
    color: #047AFA;
    line-height: 43px;
    margin-top:40px;
    span {
        color:#000;
    }
    @include below($m) {
        font-size:28px;
        line-height:38px;
    }
}
.copy {
    /* Någon typ av instruk: */
    font-weight:400;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 29px;
    position: relative;
}
.result {
    opacity: 0;
    transition: .2s;
    &.active {
        opacity: 1;
    }
    p {
        color: #000;
        letter-spacing: 0;
        line-height: 29px;
        position: relative;
        font-size: 15px;
        margin-top: 24px;
    }
}

h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    font-weight: 500;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}

.title {
    font-weight:500;
    font-size: 30px;
    color: #000000;
    letter-spacing: 2.5px;
    line-height: 58px;
    text-transform:uppercase;
    margin-bottom:0px;
    &.form-title {
        margin-bottom:24px;
    }
    &.black {
        color:black;
    }
    &.small {
        font-size:24px;
        line-height:32px;
        margin-bottom:20px;
    }
    @include below($m) {
        font-size:28px;
        line-height:38px;
        letter-spacing:2px;
    }
}
.text-block a {
    color:black;
    text-decoration: underline;
}
input[type=text] {
    border:0;
    border-bottom:1px solid rgba(0,0,0,0.5);
    margin-bottom:40px;
    display:block;
    width:100%;
    font-size: 22px;
    padding-bottom:12px;
    color: #2A2929;
    outline:none;
    transition: .2s;
    &:focus {
        outline:none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color:#000;
        opacity:0.5;
        font-style:italic;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color:#000;
        opacity:0.5;
        font-style:italic;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color:#000;
        opacity:0.5;
        font-style:italic;
    }
    :-moz-placeholder { /* Firefox 18- */
        color:#000;
        opacity:0.5;
        font-style:italic;
    }
    @include below($m) {
        font-size:18px;
    }
    &.error {
        border-left: 5px solid #FB0000;
    }
}
.livin {
    display: block;
    margin-bottom:140px;
}
.button {
    font-size:22px;
    color:#000000;
    font-weight:500;
    text-decoration: underline;
    cursor: pointer;
}
.preline {
    padding-top:24px;
    position: relative;
    &:before {
        @include pre-line(#000000, 5px);
    }
    &.blue:before {
        @include pre-line(#047AFA, 5px);
    }
}
.postline {
    padding-bottom:0px;
    margin-bottom:24px;
    position: relative;
    &:before {
        @include post-line(#000000, 5px);
    }
    &.blue:before {
        @include post-line(#047AFA, 5px);
    }
}

.timetable {
    margin-top:24px;

    .item {
        margin-bottom:24px;
    }
    p {
        font-size:16px;
        line-height:23px;
        &.bold {
            font-weight:500;
        }
        &.blue {
            color:#047AFA;
        }
    }
}

.footer {
    width: 100%;
    background: #fff;
    font-size: 14px;
    color: #0F0F0F;
    font-weight: 600;
    overflow: hidden;
    z-index: 9;
    position: relative;
    text-align:center;
}
.footer-wrap {
    width: 100%;
    max-width: 908px;
    padding: 15px 20px;
    margin: 0 auto;
}
.footer img {
    width:120px;
    margin:0 10px;
}
.footer-slogan {
    display: inline-block;
    margin-top: 10px;
    padding-bottom: 20px;
    line-height: 1.35;
    float: right;
}

.speakers {
    display: block;
}

.speaker {
    position: relative;
    padding-top:12px;
    margin-bottom:36px;
    img {
        margin:12px 0;
        width:100%;
    }
}

.signup-table {
  min-height:80vh;
  height:auto;
  padding-bottom:80px;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 3rem;
  text-align: left;
}

tr:nth-child(odd){
	background: rgba(0,0,0,.03);
}

tr:hover{
	background: rgba(0,0,0,.05);
}

th{
	color: #fff;
	padding: 20px;
}
td{
	padding: 20px;
	width: calc(100% / 3);
}
input[type="submit"] {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}